html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  color: #777777;
}

* {
  box-sizing: border-box;
}

.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .container {
    max-height: -webkit-fill-available;
  }
}

main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer div {
  margin-left: 2em;
  margin-right: 2em;
}

.spin-button {
  border: 1px solid #dddddd;
  color: #777777;
  padding: 1em;
  border-radius: 20px;
  display: "inline-block";
  cursor: pointer;
}

.spin-counter {
  font-size: smaller;
  text-align: center;
  font-family: "Lucida Console", Monaco, monospace;
}

.info {
  position: absolute;
  cursor: pointer;
  top:1em;
  right: 1em;
  color: rgb(74, 178, 219);
}

.share-buttons {
  position: absolute;
  top: 1em;
  left: 1em;
}

@-webkit-keyframes fade {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-moz-keyframes fade {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-o-keyframes fade {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fade {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

.fadeOut {
  animation: fade 3s forwards;
}

.result-text {
  margin-bottom: 1em;
}